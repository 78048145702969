import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from "moment/moment";
import 'moment-timezone';

const TrainingTable = ({ trainingData }) => {
  let { id } = useParams();
  const [isVisible, setIsVisible] = useState(true);

  // Agrupar ejercicios por superset
  const groupedExercises = [];
  const processedSupersets = new Set();
  const sortedExercises = [...trainingData.workout_exercise].sort((a, b) => a.order - b.order);

  sortedExercises.forEach((exercise) => {
    if (exercise.superset !== null) {
      if (!processedSupersets.has(exercise.superset)) {
        // Obtener todos los ejercicios en el mismo superset
        const supersetExercises = sortedExercises.filter(ex => ex.superset === exercise.superset);
        groupedExercises.push({ type: "superset", exercises: supersetExercises });
        processedSupersets.add(exercise.superset);
      }
    } else {
      groupedExercises.push({ type: "single", exercise });
    }
  });

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="container mx-auto p-4">
      {isVisible && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-200 text-left w-1/4">Exercise</th>
                <th className="py-2 px-4 border-b border-gray-200 text-left w-1/2">Objective</th>
                <th className="py-2 px-4 border-b border-gray-200 text-left w-1/2">Execution</th>
              </tr>
            </thead>
            <tbody>
              {groupedExercises.map((group, index) => {
                if (group.type === "single") {
                  const { exercise } = group;
                  const sortedObjectives = [...exercise.set_objective].sort((a, b) => a.index - b.index);
                  
                  return (
                    <tr key={exercise.id}>
                      <td className="py-2 px-4 border-b border-gray-200 align-top">
                        {exercise.exercise_details.name}
                        {exercise.note && (
                          <>
                            <br />
                            Notes: {exercise.note}
                          </>
                        )}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-200">
                        {sortedObjectives.map((objective) => (
                          <div key={objective.id} className="mb-2 flex space-x-4">
                            <p><span className="font-semibold">Set#</span> {objective.index}</p>
                            {objective.reps !== null && <p><span className="font-semibold">Reps:</span> {objective.reps}</p>}
                            {objective.reps !== null && <p><span className="font-semibold">Reps:</span> {objective.reps}</p>}
                            {objective.kg !== null && <p><span className="font-semibold">KG:</span> {objective.kg}</p>}
                            {objective.time !== null && <p><span className="font-semibold">Time:</span> {objective.time}</p>}
                            {objective.percentage_rm !== null && <p><span className="font-semibold">%RM:</span> {objective.percentage_rm}</p>}
                            {objective.rpe !== null && <p><span className="font-semibold">RPE:</span> {objective.rpe}</p>}
                            {objective.rir !== null && <p><span className="font-semibold">RIR:</span> {objective.rir}</p>}
                            {objective.tut !== null && <p><span className="font-semibold">TUT:</span> {objective.tut}</p>}
                            {objective.rest !== null && <p><span className="font-semibold">Rest:</span> {objective.rest}</p>}
                            {objective.note !== null && <p><span className="font-semibold">Notes:</span> {objective.note}</p>}
                          </div>
                        ))}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-200">
                        {sortedObjectives.map((objective) => (
                          <div key={objective.id} className="mb-2">
                            {objective.set_executed.map((execution) =>
                              execution.athlete == id ? (
                                <div key={execution.id} className="mb-2 flex space-x-4">
                                  {execution.kg !== null && (
                                    <p>
                                      <span className="font-semibold">Kg:</span> {execution.kg}
                                    </p>
                                  )}
                                  {execution.time !== null && (
                                    <p>
                                      <span className="font-semibold">Time:</span> {execution.time}
                                    </p>
                                  )}
                                  {execution.reps !== null && (
                                    <p>
                                      <span className="font-semibold">Reps:</span> {execution.reps}
                                    </p>
                                  )}
                                  {execution.note !== "" &&
                                    execution.note !== null &&
                                    execution.note !== " " && (
                                      <p>
                                        <span className="font-semibold">Notes:</span> {execution.note}
                                      </p>
                                    )}
                                  <p>
                                    <span className="font-semibold">Date:</span> {moment(execution.created_at).tz('Europe/Madrid').format('DD/MM/YYYY HH:mm:ss')}
                                  </p>
                                </div>
                              ) : null
                            )}
                          </div>
                        ))}
                      </td>
                    </tr>
                  );
                }
                // Si es una superserie, intercalamos los sets respetando el orden
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td colSpan="3" className="bg-gray-200 text-center font-bold py-2">Superset</td>
                    </tr>
                    {(() => {
                      // Obtener el número máximo de sets en la superserie
                      const maxSets = Math.max(...group.exercises.map(ex => ex.set_objective.length));
                      const interleavedRows = [];

                      for (let i = 0; i < maxSets; i++) {
                        group.exercises.forEach((exercise) => {
                          const objective = exercise.set_objective.find(set => set.index === i + 1); // Buscar set por index
                          console.log(objective);
                          if (objective) {
                            interleavedRows.push(
                              <tr key={`${exercise.id}-set-${i}`}>
                                <td className="py-2 px-4 border-b border-gray-200 align-top">
                                  {exercise.exercise_details.name}
                                </td>
                                <td className="py-2 px-4 border-b border-gray-200">
                                  <div key={objective.id} className="mb-2 flex space-x-4">
                                    <p><span className="font-semibold">Set#</span> {objective.index}</p>
                                    {objective.reps !== null && <p><span className="font-semibold">Reps:</span> {objective.reps}</p>}
                                    {objective.reps !== null && <p><span className="font-semibold">Reps:</span> {objective.reps}</p>}
                                    {objective.kg !== null && <p><span className="font-semibold">KG:</span> {objective.kg}</p>}
                                    {objective.time !== null && <p><span className="font-semibold">Time:</span> {objective.time}</p>}
                                    {objective.percentage_rm !== null && <p><span className="font-semibold">%RM:</span> {objective.percentage_rm}</p>}
                                    {objective.rpe !== null && <p><span className="font-semibold">RPE:</span> {objective.rpe}</p>}
                                    {objective.rir !== null && <p><span className="font-semibold">RIR:</span> {objective.rir}</p>}
                                    {objective.tut !== null && <p><span className="font-semibold">TUT:</span> {objective.tut}</p>}
                                    {objective.rest !== null && <p><span className="font-semibold">Rest:</span> {objective.rest}</p>}
                                    {objective.note !== null && <p><span className="font-semibold">Notes:</span> {objective.note}</p>}
                                  </div>
                                </td>
                                <td className="py-2 px-4 border-b border-gray-200">
                                  {objective.set_executed.map((execution) =>
                                    execution.athlete == id ? (
                                      <div key={execution.id} className="mb-2 flex space-x-4">
                                        {execution.kg !== null && (
                                          <p>
                                            <span className="font-semibold">Kg:</span> {execution.kg}
                                          </p>
                                        )}
                                        {execution.time !== null && (
                                          <p>
                                            <span className="font-semibold">Time:</span> {execution.time}
                                          </p>
                                        )}
                                        {execution.reps !== null && (
                                          <p>
                                            <span className="font-semibold">Reps:</span> {execution.reps}
                                          </p>
                                        )}
                                        {execution.note !== "" &&
                                          execution.note !== null &&
                                          execution.note !== " " && (
                                            <p>
                                              <span className="font-semibold">Notes:</span> {execution.note}
                                            </p>
                                          )}
                                        <p>
                                          <span className="font-semibold">Date:</span> {moment(execution.created_at).tz('Europe/Madrid').format('DD/MM/YYYY HH:mm:ss')}
                                        </p>
                                      </div>
                                    ) : null
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        });
                      }
                      return interleavedRows;
                    })()}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TrainingTable;
