import SideBar from "../../components/navigation/SideBar";
import { useNavigate, useParams, } from "react-router-dom";
import { addExerciseToWorkout, removeExerciseFromWorkout, changeOrderUp, useUpdateWorkoutMutation, changeOrderDown, addSet, removeSet, 
  useCreateWorkoutExerciseMutation, useDeleteWorkoutExerciseMutation, useCreateSetObjectiveMutation, clean, useFetchWorkoutQuery, initializewithData } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import SearchExercise from "../../components/workout/SearchExercise";
import { useDispatch, useSelector } from "react-redux";
import ExercisesInWorkoutList from "../../components/workout/ExercisesInWorkoutList";
import { useEffect } from "react";

function EditWorkoutForm(){
  let { id } = useParams();
  const dispatch = useDispatch();
  const { exercisesInWorkout } = useSelector((state) => {
    return {exercisesInWorkout: state.exercisesInWorkout.data};
  });
  
  const navigate = useNavigate();
  const [updateWorkout] = useUpdateWorkoutMutation();
  const [createWorkoutExercise] = useCreateWorkoutExerciseMutation();
  const [createSetObjective] = useCreateSetObjectiveMutation();
  const [deleteWorkoutExercise] = useDeleteWorkoutExerciseMutation();
  
  let standardForm;
  const { data, error, isLoading} = useFetchWorkoutQuery(id);
  let meta = {
    object: "Workout",
    action: "Edit workout",
    description: "Edit workout record.",
  };
  let config = [
    {
      id:'name',
      label: 'Workout name',
      type: 'shortText',
      placeholder: 'Microcycle A1',
      size: 2,
    }
  ];

  const handleSave = async (e)=> {
    e.preventDefault();
    const submitData = config.reduce((o, input) => ({ ...o, [input.id]: e.target[input.id].value}), {});
    const {data} = await updateWorkout({id, submitData});
    const createdWorkout = data[0];
    
    // Delete everything, create everything again
    workout.workout_exercise.forEach(async(workoutExercise)=>{
      deleteWorkoutExercise(workoutExercise.id);
    });
    // Create everything again
    exercisesInWorkout.forEach(async(exercise)=>{
      let {data} = await createWorkoutExercise({ workout: createdWorkout.id, exercise: exercise.id, order: exercise.order, note: exercise.note, superset:exercise.supersetGroup });
      let workoutExercise = data[0];
      exercise.sets.forEach((set)=>{
        createSetObjective({
          workout_exercise: workoutExercise.id,
          index: set.index,
          kg: set.kg,
          reps: set.reps,
          rpe: set.rpe,
          rir: set.rir,
          tut: set.tut,
          time: set.time,
          note: set.note,
          rest: set.rest,
          percentage_rm: set.percentage_rm,
        });
      });
    });
    dispatch(clean());
    navigate("/workouts");
  }
  
  const handleAddExercise = (exerciseId)=>{
    dispatch(addExerciseToWorkout({exerciseId:exerciseId, order:exercisesInWorkout.length+1}));
  };
  const handleExerciseRemove = (key)=>{
    dispatch(removeExerciseFromWorkout(key));
  }
  const handleAddSet = (key)=>{
    dispatch(addSet(key));
  }
  const handleRemoveSet = (key,setId)=>{
    dispatch(removeSet({exerciseKey:key, setId:setId}));
  }
  const handleChangeOrderUp = (key)=>{
    dispatch(changeOrderUp(key));
  }
  const handleChangeOrderDown = (key)=>{
    dispatch(changeOrderDown(key));
  }
  let workout;
  if (isLoading){
    standardForm = <div> Loading</div>
  } else if (error){
    standardForm = <div> Errors</div>
  } else {
    workout = data[0];
    let formValue = {name: workout.name}
    standardForm = <StandardForm meta={meta} data={formValue} config={config} colsForm={1} onSubmit={handleSave}/>;
  }

  useEffect(()=>{
    if (workout) {
      dispatch(initializewithData(workout));
    }
  }, [dispatch, workout]);

  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {standardForm}
          
        </div>
        <div className="mx-auto max-w-12xl px-4 py-2 sm:px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div className="lg:col-start-3 lg:row-end-1">
                <SearchExercise onAddExercise={handleAddExercise}/>
              </div>

              {/*Left */}
              <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-2 xl:pb-10 xl:pt-8">
                <h2 className="text-base pb-6 font-semibold leading-6 text-gray-900">Exercises in workout</h2>
                <ExercisesInWorkoutList exercises={exercisesInWorkout} onClickRemove={handleExerciseRemove} onClickAddSet={handleAddSet} onClickRemoveSet={handleRemoveSet} onClickOrderUp={handleChangeOrderUp} onClickOrderDown={handleChangeOrderDown}/>
              </div>
            </div>
          </div>
      </main>
    </div>
    </>
  );
}

export default EditWorkoutForm;