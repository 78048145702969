import SideBar from "../../components/navigation/SideBar";
import { useNavigate } from "react-router-dom";
import { useCreateFoodMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import { useState } from "react";

function CreateFood(){
  const navigate = useNavigate();
  const [createFood] = useCreateFoodMutation();
  const [selectedPreference1, setSelectedPreference1] = useState({
    "id": 1,
    "value": "Breakfast",
    "label": "Breakfast"
  })
  const onHandlePreference1Change = (e)=> {
    setSelectedPreference1(e);
  }
  const [selectedPreference2, setSelectedPreference2] = useState({
    "id": 4,
    "value": "Snack",
    "label": "Snack"
  })
  const onHandlePreference2Change = (e)=> {
    setSelectedPreference2(e);
  }
  const [selectedType, setSelectedType] = useState({
    "id": 1,
    "value": "Natural Raw",
    "label": "Natural Raw"
  })
  const onHandleTypeChange = (e)=> {
    setSelectedType(e);
  }

  const meta = {
    object: "Food",
    action: "Create new Food",
    description: "Create a new food record.",
  };
  let config = [
    {
      id:'name_en',
      label: 'English Name',
      type: 'shortText',
      placeholder: 'Egss',
      size: 2
    },
    {
      id:'name_es',
      label: 'Spanish Name',
      type: 'shortText',
      placeholder: 'Huevos',
      size: 2
    },
    {
      id:'meal_preference1',
      label: 'Meal Preference 1',
      type: 'dropdown',
      placeholder: 'Choose preference',
      options: [
        { id: 1, value: 'Breakfast', label: 'Breakfast' },
        { id: 2, value: 'Lunch', label: 'Lunch' },
        { id: 3, value: 'Dinner', label: 'Dinner' },
        { id: 4, value: 'Snack', label: 'Snack' },
      ],
      size: 2,
      onChange: onHandlePreference1Change,
    },
    {
      id:'meal_preference2',
      label: 'Meal Preference 2',
      type: 'dropdown',
      placeholder: 'Choose preference',
      options: [
        { id: 4, value: 'Snack', label: 'Snack' },
        { id: 1, value: 'Breakfast', label: 'Breakfast' },
        { id: 2, value: 'Lunch', label: 'Lunch' },
        { id: 3, value: 'Dinner', label: 'Dinner' },
      ],
      size: 2,
      onChange: onHandlePreference2Change,
    },
    {
      id:'type',
      label: 'Type',
      type: 'dropdown',
      placeholder: 'Choose type',
      options: [
        { id: 1, value: 'Natural Raw', label: 'Natural Raw' },
        { id: 2, value: 'Natural Cooked', label: 'Natural Cooked' },
        { id: 3, value: 'Product', label: 'Product' },
      ],
      size: 2,
      onChange: onHandleTypeChange,
    },
    {
      id:'serving_size',
      label: 'Serving Size',
      type: 'unit',
      unit: 'g',
      size: 2
    },
    {
      id:'calories',
      label: 'Calories',
      type: 'unit',
      unit: 'kcal',
      size: 2
    },
    {
      id:'carbs',
      label: 'Carbs',
      type: 'unit',
      unit: 'g',
      size: 2
    },
    {
      id:'protein',
      label: 'Protein',
      type: 'unit',
      unit: 'g',
      size: 2
    },
    {
      id:'fat',
      label: 'Fat',
      type: 'unit',
      unit: 'g',
      size: 2
    },
    {
      id:'link_en',
      label: 'English Link',
      type: 'shortText',
      size: 2
    },
    {
      id:'link_es',
      label: 'Spanish Link',
      type: 'shortText',
      size: 2
    },
  ];
  
  const handleCreate = (e) => {
    e.preventDefault();
  
    e.target['meal_preference1'] = selectedPreference1;
    e.target['meal_preference2'] = selectedPreference2;
    e.target['type'] = selectedType;
  
    // Preparar el objeto 'submitData' con los datos del formulario
    const submitData = config.reduce((o, input) => {
      // Si el tipo del campo es checkbox, obtener su estado 'checked'
      if (input.type === 'check') {
        const checkbox = e.target.elements[input.id];
        return {
          ...o,
          [input.id]: checkbox ? checkbox.checked : false,
        };
      }
  
      // Para otros tipos de campos, usar el valor
      return {
        ...o,
        [input.id]: e.target[input.id]?.value,
      };
    }, {});
    
    createFood(submitData);
    navigate("/foods");
  };
  

  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <StandardForm meta={meta} config={config} onSubmit={handleCreate}/>
        </div>
      </main>
    </div>
    </>
  );
}

export default CreateFood;