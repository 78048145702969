import SideBar from "../../components/navigation/SideBar";
import { useNavigate, useParams } from "react-router-dom";
import SearchWorkout from "../../components/workout/SearchWorkout";
import WorkoutsInProgramList from "../../components/workout/WorkoutsInProgramList";
import { useDispatch, useSelector } from "react-redux";
import StandardForm from "../../components/forms/StandardForm";
import { addWorkoutToProgram, changeProgramOrderDown, changeProgramOrderUp, cleanProgram, initializeProgramWithData, removeWorkoutFromProgram, useCreateProgramMutation, useFetchProgramQuery, useUpdateProgramMutation, useUpdateWorkoutMutation } from "../../store";
import { useEffect, useState } from "react";

function EditProgram(){
  let { id } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [updateProgram] = useUpdateProgramMutation();
  const [updateWorkout] = useUpdateWorkoutMutation();
  const [selectedType, setSelectedType] = useState({
    "id": 1,
    "value": "Bodybuilding",
    "label": "Bodybuilding"
  });
  const onHandleTypeChange = (e)=> {
    setSelectedType(e);
  };
  
  const meta = {
    object: "Program",
    action: "Edit Program",
    description: "Edit the macrocyle",
  };
  const { workoutsInProgram } = useSelector((state) => {
    return {workoutsInProgram: state.workoutsInProgram.data};
  });
  const handleSave = async (e)=> {
    e.preventDefault();
    e.target['type'] = selectedType;
    const submitData = config.reduce((o, input) => ({ ...o, [input.id]: e.target[input.id].value}), {});
    const {data} = await updateProgram({id,submitData}); 
    workoutsInProgram.forEach(async(workout)=>{
      let updatedData = {program:id, order:workout.order};
        await updateWorkout({id:workout.id, submitData:updatedData});
      });
    dispatch(cleanProgram());
    navigate("/programs");
  }
  const handleAddWorkout = (workoutId)=>{ 
    dispatch(addWorkoutToProgram({workoutId:workoutId, order:workoutsInProgram.length+1}));
  };
  const handleWorkoutRemove = async (key)=>{
    let workoutToRemove = workoutsInProgram.filter((workout) => {
      return workout.key === key;
    });
    let updatedData = {program:null, order:null};
    dispatch(removeWorkoutFromProgram(key));
    console.log(workoutToRemove);
    await updateWorkout({id:workoutToRemove[0].id, submitData:updatedData});
  }
  const handleChangeOrderUp = (key)=>{
    dispatch(changeProgramOrderUp(key)); 
  }
  const handleChangeOrderDown = (key)=>{
    dispatch(changeProgramOrderDown(key));
  }
  const { data, error, isLoading} = useFetchProgramQuery(id);

  useEffect(()=>{
    if (data && data.length > 0) {
      let program = data[0];
      dispatch(initializeProgramWithData(program));
    }
    if (data && data.length > 0) {
      let program = data[0];
      let valueOfType = program.type;
      let valueOfTypeObject = {};
      if (valueOfType === "Bodybuilding") {
        valueOfTypeObject = {
          id: 1,
          value: "Bodybuilding",
          label: "Bodybuilding"
        };
      } else if(valueOfType === "Mobility"){
        valueOfTypeObject = {
          id: 2,
          value: "Mobility",
          label: "Mobility"
        };
      }
      setSelectedType(valueOfTypeObject);
    }
  }, [dispatch, data]);

  const config = [
    {
      id:'name',
      label: 'Program name',
      type: 'shortText',
      placeholder: 'Macrocycle 1',
      size: 2
    },
    {
      id:'type',
      label: 'Type',
      type: 'dropdown',
      placeholder: 'Choose type',
      options: [
        { id: 1, value: 'Bodybuilding', label: 'Bodybuilding' },
        { id: 2, value: 'Mobility', label: 'Mobility' },
      ],
      size: 2,
      onChange: onHandleTypeChange,
    },
  ];
  
  let program;
  let standardForm;
  if (isLoading){
    standardForm = <div> Loading</div>
  } else if (error){
    standardForm = <div> Errors</div>
  } else {
    let program = {...data[0]};
    if(program.type ==="Bodybuilding"){
      program.type = {
        "id": 1,
        "value": "Bodybuilding",
        "label": "Bodybuilding"
      }
    }else if(program.type ==="Mobility"){
      program.type = {
        "id": 2,
        "value": "Mobility",
        "label": "Mobility"
      }
    }
    let formValue = {name: program.name, type: program.type}

    standardForm = <StandardForm meta={meta} data={formValue} config={config} colsForm={3} onSubmit={handleSave}/>;
  }

  return(
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {standardForm}
        </div>
        <div className="mx-auto max-w-12xl px-4 py-2 sm:px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div className="lg:col-start-3 lg:row-end-1">
                <SearchWorkout onAddWorkout={handleAddWorkout}/>
              </div>

              {/*Left */}
              <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-2 xl:pb-10 xl:pt-8">
                <h2 className="text-base pb-6 font-semibold leading-6 text-gray-900">Workouts in program</h2>
                
                <WorkoutsInProgramList workouts={workoutsInProgram} onClickRemove={handleWorkoutRemove} onClickOrderUp={handleChangeOrderUp} onClickOrderDown={handleChangeOrderDown}/>
              </div>
            </div>
          </div>
      </main>
    </div>
    </>
  );
}

export default EditProgram;