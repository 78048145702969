import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const programApi = createApi({
  reducerPath: 'programs',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchPrograms: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Program'}];
        },
        query: (coach) => {
          return {
            url:'/program',
            params:{
              coach:"eq."+coach,
              order:"id.desc"
              // userId: user.id
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createProgram: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Program'}];
        },
        query: (data) => {
          return {
            url:'/program',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Prefer": "return=representation",
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateProgram: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Program'}];
        },
        query: ({id:programId,submitData}) => {
          return {
            url:'/program',
            params:{
              id:"eq."+programId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Prefer": "return=representation",
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      deleteProgram: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Program'}];
        },
        query: (programId) => {
          return {
            url:'/program',
            params:{
              id:"eq."+programId
            },
            
            method: 'DELETE',
            headers: authHeaders
          }
        }
      }),
      fetchProgram: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Program'}];
        },
        query: (programId) => {
          return {
            url:'/program',
            params:{
              id:"eq."+programId,
              select: "*,workout(*)"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchAllProgramDetailsByAthlete: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Program'}];
        },
        query: ({athleteId, programId}) => {
          return {
            url:'/program_athlete',
            params:{
              "athlete":"eq."+athleteId,
              "program":"eq."+programId,
              select: "*,program_details:program(*, workout(*,workout_exercise(*,exercise_details:exercise(*),set_objective(*,set_executed(*)))))"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchProgramsQuery, useCreateProgramMutation, useUpdateProgramMutation, useDeleteProgramMutation, useFetchProgramQuery, useLazyFetchProgramQuery, useFetchAllProgramDetailsByAthleteQuery } = programApi;
export { programApi };