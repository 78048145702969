import { createSlice, nanoid } from '@reduxjs/toolkit';

const exercisesInWorkoutSlice = createSlice({
  name: 'exercisesInWorkout',
  initialState: {
    data: [],
  },
  reducers: {
    addExerciseToWorkout(state, action) {
      state.data.push({
        key: nanoid(),
        id: action.payload.exerciseId,
        order: action.payload.order,
        note: null,
        supersetGroup: null,
        sets:[
          {
            id: nanoid(),
            index: 1,
            kg: null,
            reps: 10,
            rpe: null,
            rir: null,
            tut: null,
            time: null,
            rest: "1'",
            percentage_rm: null,
            note: null
          },{
            id: nanoid(),
            index: 2,
            kg: null,
            reps: 10,
            rpe: null,
            rir: null,
            tut: null,
            time: null,
            rest: "1'",
            percentage_rm: null,
            note: null
          },{
            id: nanoid(),
            index: 3,
            kg: null,
            reps: 10,
            rpe: null,
            rir: null,
            tut: null,
            time: null,
            rest: "1'",
            percentage_rm: null,
            note: null
          }
        ]
      });
    },
    removeExerciseFromWorkout(state, action) {
      let updated = state.data.filter((exercise) => {
        return exercise.key !== action.payload;
      });
      updated = updated.map((exercise, index)=>{
          exercise.order = index+1;
        return exercise;
      })
      state.data = updated;
    },
    changeOrderUp(state, action) {
    
      let targetExercise = state.data.find(exercise => exercise.key === action.payload);
      if (!targetExercise || targetExercise.order === 1) return;
    
      let movingExercises = [];
    
      // Si el ejercicio pertenece a una superserie, movemos la superserie completa
      if (targetExercise.supersetGroup !== null) {
        movingExercises = state.data.filter(ex => ex.supersetGroup === targetExercise.supersetGroup);
      } else {
        movingExercises = [targetExercise];
      }
    
      // Buscar el ejercicio previo (o superserie previa)
      let previousExercise = state.data.find(ex => ex.order === movingExercises[0].order - 1);
      if (!previousExercise) return;
    
      let previousExercises = [];
      if (previousExercise.supersetGroup !== null) {
        previousExercises = state.data.filter(ex => ex.supersetGroup === previousExercise.supersetGroup);
      } else {
        previousExercises = [previousExercise];
      }
    
      // Intercambiar órdenes
      movingExercises.forEach(ex => ex.order -= previousExercises.length);
      previousExercises.forEach(ex => ex.order += movingExercises.length);
    
      // Reordenar la lista después de cambiar los valores
      state.data.sort((a, b) => a.order - b.order);
    },
    changeOrderDown(state, action) {
    
      let targetExercise = state.data.find(exercise => exercise.key === action.payload);
      if (!targetExercise || targetExercise.order === state.data.length) return;
    
      let movingExercises = [];
    
      // Si el ejercicio pertenece a una superserie, movemos la superserie completa
      if (targetExercise.supersetGroup !== null) {
        movingExercises = state.data.filter(ex => ex.supersetGroup === targetExercise.supersetGroup);
      } else {
        movingExercises = [targetExercise];
      }
    
      // Buscar el ejercicio siguiente (o superserie siguiente)
      let nextExercise = state.data.find(ex => ex.order === movingExercises[movingExercises.length - 1].order + 1);
      if (!nextExercise) return;
    
      let nextExercises = [];
      if (nextExercise.supersetGroup !== null) {
        nextExercises = state.data.filter(ex => ex.supersetGroup === nextExercise.supersetGroup);
      } else {
        nextExercises = [nextExercise];
      }
    
      // Intercambiar órdenes
      movingExercises.forEach(ex => ex.order += nextExercises.length);
      nextExercises.forEach(ex => ex.order -= movingExercises.length);
    
      // Reordenar la lista después de cambiar los valores
      state.data.sort((a, b) => a.order - b.order);
    },
    addSet(state, action) {
      let exercise = state.data.filter((workout) => {
        return workout.key === action.payload;
      }); //I take the target
      //add set
      let newSet = {
        id: nanoid(),
        index: exercise[0].sets.length+1,
        kg: null,
        reps: 10,
        rpe: null,
        rir: null,
        tut: null,
        rest: "1'",
        percentage_rm: null,
      }
      exercise[0].sets = [...exercise[0].sets, newSet]
      //Remove the old exercise
      let updated = state.data.filter((workout) => {
        return workout.key !== exercise[0].key;
      });
      //Insert the new exercise with the set updated
      updated = [...updated, exercise[0]];
      //Reordering
      updated.sort((a,b) => a.order - b.order);
      //Update state
      state.data = updated;
    },
    removeSet(state, action){
      const {exerciseKey, setId} = action.payload;
      let exercise = state.data.filter((workout) => {
        return workout.key === exerciseKey;
      }); //I take the exercise target
      //I delete the set from the exercise
      let sets = exercise[0].sets.filter((set) => {
        return set.id !== setId;
      });
      //Updated sets index
      sets = sets.map((set, index)=>{
        set.index = index+1;
        return set;
      })
      //Reorder sets
      sets.sort((a,b) => a.index - b.index);
      //Update sets in exercise.
      exercise[0].sets = sets;
      //Remove the old exercise
      let updated = state.data.filter((exercise) => {
        return exercise.key !== exerciseKey;
      });
      //Insert the new exercise with the sets updated
      updated = [...updated, exercise[0]];
      //Reordering
      updated.sort((a,b) => a.order - b.order);
      //Update state
      state.data = updated;
    },
    editSet(state, action){
      const {exerciseKey, newSet} = action.payload;
      let exercise = state.data.filter((workout) => {
        return workout.key === exerciseKey;
      }); //I take the exercise target
      //I delete the set from the exercise
      let sets = exercise[0].sets.filter((set) => {
        return set.id !== newSet.id;
      });
      //I introduce the new set
      sets = [...sets, newSet];
      //Reordering of sets
      sets.sort((a,b) => a.index - b.index);
      //Update sets in exercise.
      let updatedExercise = {...exercise[0], sets: sets}
      //Remove the old exercise
      let updated = state.data.filter((exercise) => {
        return exercise.key !== exerciseKey;
      });
      //Insert the new exercise with the sets updated
      updated = [...updated, updatedExercise];
      //Reordering
      updated.sort((a,b) => a.order - b.order);
      //Update state
      state.data = updated;
    },
    clean(state, action){
      const updated = [];
      state.data = updated;
    },
    initializewithData(state,action){
      let updated = [];
      action.payload.workout_exercise.forEach((exerciseInWorkout)=>{
        let newSets = [...exerciseInWorkout.set_objective];
        newSets.sort((a,b) => a.index - b.index);
        updated.push({
          key: nanoid(),
          id: exerciseInWorkout.exercise.id,
          order: exerciseInWorkout.order,
          note: exerciseInWorkout.note,
          supersetGroup: exerciseInWorkout.superset,
          sets: newSets,
        })
      });
      updated.sort((a,b) => a.order - b.order);
      state.data = updated;
    },
    switchExercise(state, action){
      const {exerciseKey, newExerciseId} = action.payload;
      let targetExercise = state.data.filter((exercise) => {
        return exercise.key === exerciseKey;
      }); //I take the exercise target
      let exercise = targetExercise[0];
      exercise.id = newExerciseId;
      let updated = state.data.filter((exercise) => {
        return exercise.key !== targetExercise[0].key;
      });//I delete the old exercise
      updated = [...updated, exercise];//I insert the new one
      updated.sort((a,b) => a.order - b.order);//reordering
      state.data = updated;
    },
    editExerciseNote(state, action){
      const {exerciseKey, note} = action.payload;
      let targetExercise = state.data.filter((exercise) => {
        return exercise.key === exerciseKey;
      }); //I take the exercise target
      let exercise = targetExercise[0];
      exercise.note = note;
      let updated = state.data.filter((exercise) => {
        return exercise.key !== targetExercise[0].key;
      });//I delete the old exercise
      updated = [...updated, exercise];//I insert the new one
      updated.sort((a,b) => a.order - b.order);//reordering
      state.data = updated;
    },
    addToSuperset(state, action) { // dispatch(addToSuperset({ exerciseKeys: ['key1', 'key2', 'key3'] }));
      const { exerciseKeys } = action.payload; // Lista de keys de ejercicios
      // Verificar si alguno ya pertenece a una superserie
      let existingGroup = null;
      state.data.forEach(exercise => {
        if (exerciseKeys.includes(exercise.key) && exercise.supersetGroup) {
          existingGroup = exercise.supersetGroup;
        }
      });
    
      // Si no hay un grupo existente, creamos uno nuevo
      const newGroup = existingGroup || nanoid();
    
      state.data = state.data.map(exercise => {
        if (exerciseKeys.includes(exercise.key)) {
          return { ...exercise, supersetGroup: newGroup };
        }
        return exercise;
      });
    },
    removeFromSuperset(state, action) { //dispatch(removeFromSuperset({ exerciseKey: 'key2' }));
      const { exerciseKey } = action.payload;
    
      // Obtener el ejercicio objetivo
      let targetExercise = state.data.find(exercise => exercise.key === exerciseKey);
      if (!targetExercise || targetExercise.supersetGroup === null) return;
    
      // Obtener todos los ejercicios de la superserie
      let supersetExercises = state.data.filter(exercise => exercise.supersetGroup === targetExercise.supersetGroup);
    
      // Si solo hay 2 ejercicios en la superserie, eliminar la superserie por completo
      if (supersetExercises.length <= 2) {
        supersetExercises.forEach(ex => {
          ex.supersetGroup = null; // Eliminar la referencia a la superserie
        });
      } else {
        // Si hay más de 2, simplemente eliminar este ejercicio de la superserie
        targetExercise.supersetGroup = null;
      }
    
      // Reordenar para mantener consistencia
      state.data.sort((a, b) => a.order - b.order);
    },
    removeSuperset(state, action) { //dispatch(removeSuperset({ supersetGroup: 'abc123' }));
      const { supersetGroup } = action.payload;
    
      state.data = state.data.map(exercise => {
        if (exercise.supersetGroup === supersetGroup) {
          return { ...exercise, supersetGroup: null };
        }
        return exercise;
      });
    },
    
  },
});

export const { addExerciseToWorkout, removeExerciseFromWorkout, changeOrderUp, changeOrderDown, addSet, 
  removeSet, editSet, clean, initializewithData, switchExercise, editExerciseNote, addToSuperset, removeFromSuperset, removeSuperset } = exercisesInWorkoutSlice.actions;
export const exercisesInWorkoutReducer = exercisesInWorkoutSlice.reducer;
