import React, { useState, useEffect } from 'react';
import DateInput from '../forms/inputs/DateInput';
import Dropdown from '../forms/inputs/DropDown';
import FoldInputs from './FoldInputs';
import { calculatePlicometry } from '../../services/plicometry.service';
import { useCreatePlicometryMutation } from '../../store';

function PlicoCalculator({athlete}) {
  const [selectedMethod, setSelectedMethod] = useState('Jackson & Pollock 7 skin folds');
  const [visibleInputs, setVisibleInputs] = useState([]);
  const [extraInputs, setExtraInputs] = useState([]);
  const [showRemoveButton, setShowRemoveButton] = useState(false);
  const [createPlico] = useCreatePlicometryMutation();

  const [formData, setFormData] = useState({
    weight: "",
    height: "",
    date: null,
    notes: '', // Estado para las notas
    chest: "",
    midaxilla: "",
    abdomen: "",
    suprailiac: "",
    quadriceps: "",
    subscapular: "",
    triceps: "",
  });

  useEffect(() => {
    updateVisibleInputs(selectedMethod);
  }, [selectedMethod]);

  const handleMethodChange = (selectedOption) => {
    setSelectedMethod(selectedOption.value);
  };

  const updateVisibleInputs = (method) => {
    switch (method) {
      case 'Jackson & Pollock 7 skin folds':
        setVisibleInputs(['chest', 'midaxilla', 'abdomen', 'suprailiac', 'quadriceps', 'subscapular', 'triceps']);
        break;
      case 'Jackson & Pollock 4 skin folds':
        setVisibleInputs(['abdomen', 'suprailiac', 'quadriceps', 'triceps']);
        break;
      case 'Jackson & Pollock 3 skin folds':
        setVisibleInputs(['chest', 'abdomen', 'quadriceps']);
        break;
      default:
        setVisibleInputs([]);
    }
  };

  const addExtraInput = () => {
    setExtraInputs([...extraInputs, { id: Date.now(), name: '', value: '' }]);
    setShowRemoveButton(true);
  };

  const handleExtraInputChange = (index, field, value) => {
    const newExtraInputs = [...extraInputs];
    newExtraInputs[index][field] = value;
    setExtraInputs(newExtraInputs);
  };

  const removeLastExtraInput = () => {
    const newExtraInputs = extraInputs.slice(0, -1);
    setExtraInputs(newExtraInputs);
    if (newExtraInputs.length === 0) {
      setShowRemoveButton(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePlicometryCalculation = () => {
    const data = {
      ...formData,
      method: selectedMethod, 
      athlete: athlete.id,
      other: extraInputs.map(input => ({
        name: input.name,
        value: input.value,
      })),
    };
    let plicoResults = calculatePlicometry(data,athlete, selectedMethod);
    createPlico(data);
  };

  const inputFields = [
    { id: 'chest', label: 'Chest', unit: 'mm' },
    { id: 'midaxilla', label: 'Midaxilla', unit: 'mm' },
    { id: 'abdomen', label: 'Abdomen', unit: 'mm' },
    { id: 'suprailiac', label: 'Suprailiac', unit: 'mm' },
    { id: 'quadriceps', label: 'Quadriceps', unit: 'mm' },
    { id: 'subscapular', label: 'Subscapular', unit: 'mm' },
    { id: 'triceps', label: 'Triceps', unit: 'mm' }
  ];

  return (
    <div>
      <div className="max-w-6xl p-4">
        <h2 className="text-2xl font-semibold leading-6 text-gray-900 mb-6">Composition entry</h2>
        
        <div className="flex space-x-1">
          <div className="flex-1">
            <label htmlFor="weight" className="block text-sm font-medium leading-6 text-gray-900">Weight</label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                name="weight"
                id="weight"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="weight-unit"
                value={formData.weight}
                onChange={handleInputChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="weight-unit">kg</span>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <label htmlFor="height" className="block text-sm font-medium leading-6 text-gray-900">Height</label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                name="height"
                id="height"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="height-unit"
                value={formData.height}
                onChange={handleInputChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="height-unit">cm</span>
              </div>
            </div>
          </div>
          
          <div className="flex-1">
            <DateInput
              key="date-input"
              id="date-input"
              label="Date"
              placeholder="Select a date"
              size="medium"
              value={formData.date}
              onChange={(date) => setFormData({ ...formData, date:date.startDate })}
            />
          </div>
          <div className="grow">
            <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">Notes</label>
            <textarea
              id="notes"
              name="notes"
              rows="3"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Add notes here..."
              value={formData.notes}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="border-t border-gray-900/10 pb-12 p-4 mt-4">
        <div className="flex space-x-4">
          <div className="flex-1">
            <label htmlFor="method" className="block text-sm font-medium leading-6 text-gray-900">Method</label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <Dropdown
                id="method"
                options={[
                  { id: 1, value: 'Jackson & Pollock 7 skin folds', label: 'Jackson & Pollock 7 skin folds' },
                  { id: 2, value: 'Jackson & Pollock 4 skin folds', label: 'Jackson & Pollock 4 skin folds' },
                  { id: 3, value: 'Jackson & Pollock 3 skin folds', label: 'Jackson & Pollock 3 skin folds' },
                ]}
                onChange={handleMethodChange}
              />
            </div>
          </div>

          {inputFields.map((inputField) => (
            <FoldInputs 
              key={inputField.id} 
              id={inputField.id} 
              label={inputField.label} 
              unit={inputField.unit} 
              isVisible={visibleInputs.includes(inputField.id)}
              value={formData[inputField.id]}
              onChange={handleInputChange}
            />
          ))}
        </div>
        
        <div className="mt-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Specific critical folds</h3>
          {extraInputs.map((extraInput, index) => (
            <div key={extraInput.id} className="flex space-x-4 mt-4">
              <div className="flex-1">
                <label htmlFor={`extra-name-${extraInput.id}`} className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                <input
                  type="text"
                  name={`extra-name-${extraInput.id}`}
                  id={`extra-name-${extraInput.id}`}
                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Fold name"
                  value={extraInput.name}
                  onChange={(e) => handleExtraInputChange(index, 'name', e.target.value)}
                />
              </div>
              <div className="flex-1">
                <label htmlFor={`extra-value-${extraInput.id}`} className="block text-sm font-medium leading-6 text-gray-900">Value</label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="number"
                    step="0.01"
                    name={`extra-value-${extraInput.id}`}
                    id={`extra-value-${extraInput.id}`}
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                    value={extraInput.value}
                    onChange={(e) => handleExtraInputChange(index, 'value', e.target.value)}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span className="text-gray-500 sm:text-sm">mm</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-4">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={addExtraInput}
          >
            Add another specific critical fold that you want to keep track
          </button>
          {showRemoveButton && (
            <button
              type="button"
              className="inline-flex items-center px-4 ml-2 py-2 border border-red-600 text-red-600 text-sm font-medium rounded-md shadow-sm bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={removeLastExtraInput}
            >
              Remove last fold
            </button>
          )}
        </div>

        <div className="mt-4">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-green-600 text-green-600 text-sm font-medium rounded-md shadow-sm bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={handlePlicometryCalculation}
          >
            Calculate and register measurement
          </button>
        </div>
      </div>
    </div>
  );
}

export default PlicoCalculator;
