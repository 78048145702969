import PlicoCalculator from "../../../components/plicometry/PlicoCalculator";
import { useParams } from "react-router-dom";
import { useFetchAthleteQuery } from "../../../store";
import PlicoTable from "../../../components/plicometry/PlicoTable";
import PlicoChart from "../../../components/plicometry/PlicoChart";
import FatForm from "../../../components/plicometry/FatForm";

function PlicometryManager() {
  let { id } = useParams();
  const { data, error, isLoading} = useFetchAthleteQuery(id);
  let athlete;
  let content;
  if (isLoading) {
    content = <div> Loading...</div>;
    return content;
  }
  if (error) {
    content = <div> Error: {error.message}</div>;
    return content;
  }
  else{
    athlete = data[0];
    content = <div className="">
      <div className=" border-b border-gray-900/10 pb-12">
        <PlicoChart athlete={athlete}/>
      </div>
      <div className="border-t border-gray-900/10 pb-12">
        <PlicoTable athlete={athlete}/>
      </div>
      <div className="border-t border-gray-900/10 pb-12">
        <PlicoCalculator athlete={athlete}/>
      </div>
      <div className="border-t border-gray-900/10 pb-12">
        <FatForm athlete={athlete}/>
      </div>
    </div>;
  }
  return (
    content
);
}

export default PlicometryManager;