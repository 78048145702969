import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CakeIcon } from '@heroicons/react/24/outline'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCreateDietMutation, useFetchFoodsQuery } from '../../store';
import OpenAI from 'openai';
import Meal from '../nutrition/Meal';
import MealPlanMaker from '../nutrition/MealPlanMaker';

export default function CreateMealPlanModal({ open, setOpen, dietNeed }) {
  const cancelButtonRef = useRef(null);
  const [createDiet] = useCreateDietMutation();
  let { id:athleteId } = useParams();
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true 
  });

  const { mealPlan } = useSelector((state) => {
    return { mealPlan: state.mealPlanMaker };
  });
  const handleSave = (e)=>{
    e.preventDefault();
    createDiet({
      athlete: athleteId,
      meal_plan: {
        calories: mealPlan.calories,
        carbs: mealPlan.carbs,
        protein: mealPlan.protein,
        fat: mealPlan.fat,
        meals: mealPlan.meals 
      },
      diet_need: dietNeed.id
    });
  }
  const { data:foodData, error, isLoading} = useFetchFoodsQuery();
  let foods;
  if (isLoading){
    foods = <div> Loading</div>
  } else if (error){
    foods = <div> Errors retrieving food data</div>
  } else {
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed top-0 left-0 w-full h-full z-50 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="flex min-h-screen items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden w-full md:w-10/12 lg:w-10/12 bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 rounded-lg">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                <CakeIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  Create Meal Plan
                </Dialog.Title>
                <div className="mt-2">
                  <MealPlanMaker dietNeed={dietNeed}/>
                </div>
              </div>
              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="mr-2 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSave}
                  className="inline-flex justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Save diet
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
