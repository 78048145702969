import {
  FireIcon
} from '@heroicons/react/20/solid'
import { useFetchFoodsQuery } from '../../store';
import { useEffect, useState } from 'react';


function SearchFood({onAddFood}){
  const [term, setTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const { data, error, isLoading} = useFetchFoodsQuery();
  let renderedFoods;
  if (isLoading){
    renderedFoods = <div> Loading</div>
  } else if (error){
    renderedFoods = <div> Errors</div>
  } else {
    renderedFoods = <FoodCards foods={searchResult} onClick={onAddFood} />;
  }
  
  useEffect(() => {
    if (!isLoading && !error) {
      setSearchResult(data);
    }
  }, [data, isLoading, error]);


  const handleChange = (e) =>{
    const value = e.target.value;
    setTerm(value);
    // Filtrar los resultados basados en el valor de term
    const filteredResultsEnglish = data.filter(food => 
      food.name_en.toLowerCase().includes(value.toLowerCase())
    );
    const filteredResultsSpanish = data.filter(food => 
      food.name_es.toLowerCase().includes(value.toLowerCase())
    );
    const results = [...filteredResultsEnglish,...filteredResultsSpanish];
    const seen = new Set();
    const update = results.filter(obj => {
    // Usar una clave única para identificar duplicados, como el id
    const key = obj.id; // Puedes cambiar esto si necesitas una clave diferente
    if (!seen.has(key)) {
        seen.add(key);
        return true;
    }
    return false;
});
    setSearchResult(update);
  };

  return (
    <>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className='mt-6 px-6 w-full '>
            <label htmlFor="exercise" className="block text-sm font-medium leading-6 text-gray-900">
              Search food
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <FireIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="food"
                name="food"
                id="food"
                value={term}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Rice"
              />
            </div>
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6 mb-6">
            {renderedFoods}
          </div>
        </dl>
        
      </div>
    </>
  );
}

function FoodCards ({foods=[], onClick}){
  const renderedFoods = foods.map((food) => {
    const handleAdd = (food) =>{
      onClick(food);
    };
    return (
      <li key={food.id} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
        <div className="flex min-w-0 gap-x-4">
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-gray-900">
                {food.name_en}
            </p>
            <p className="mt-1 flex text-xs leading-5 text-gray-500 relative truncate hover:underline">
                {food.name_es}
            </p>
          </div>
        </div>
        <div className="flex flex-none items-center gap-x-4">
          <button
            onClick= {() => {
              handleAdd(food)
            }}
            className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
          >
            Add to meal<span className="sr-only">, {food.name_en}</span>
          </button>
          
        </div>
      </li>
    )
  });
  return (
    <>
      <ul
        className="divide-y w-full divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
      >
         {renderedFoods}
      </ul>
    </>
  );
}

export default SearchFood;