import React, { useState } from 'react';
import DateInput from '../forms/inputs/DateInput';
import { useCreatePlicometryMutation } from '../../store';

function FatForm({athlete}) {
  const [createPlico] = useCreatePlicometryMutation();

  const [formData, setFormData] = useState({
    weight: "",
    height: "",
    date: null,
    notes: '', 
    lbm: "",
    fat_percentage: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    const data = {
      ...formData,
      athlete: athlete.id,
    };
    createPlico(data);
  };

  return (
    <div>
      <div className="max-w-6xl p-4">
        <h2 className="text-2xl font-semibold leading-6 text-gray-900 mb-6">Composition entry</h2>
        
        <div className="flex space-x-1">
          <div className="flex-1">
            <label htmlFor="weight" className="block text-sm font-medium leading-6 text-gray-900">Weight</label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                name="weight"
                id="weight"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="weight-unit"
                value={formData.weight}
                onChange={handleInputChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="weight-unit">kg</span>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <label htmlFor="height" className="block text-sm font-medium leading-6 text-gray-900">Height</label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                name="height"
                id="height"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="height-unit"
                value={formData.height}
                onChange={handleInputChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="height-unit">cm</span>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <label htmlFor="weight" className="block text-sm font-medium leading-6 text-gray-900">Lbm</label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                name="lbm"
                id="lbm"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="lbm-unit"
                value={formData.lbm}
                onChange={handleInputChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="weight-unit">kg</span>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <label htmlFor="weight" className="block text-sm font-medium leading-6 text-gray-900">Fat %</label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                name="fat_percentage"
                id="fat_percentage"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="fat_percentage-unit"
                value={formData.fat_percentage}
                onChange={handleInputChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="weight-unit">%</span>
              </div>
            </div>
          </div>
          
          <div className="flex-1">
            <DateInput
              key="date-input"
              id="date-input"
              label="Date"
              placeholder="Select a date"
              size="medium"
              value={formData.date}
              onChange={(date) => setFormData({ ...formData, date:date.startDate })}
            />
          </div>
          <div className="grow">
            <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">Notes</label>
            <textarea
              id="notes"
              name="notes"
              rows="3"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Add notes here..."
              value={formData.notes}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="mt-4">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-green-600 text-green-600 text-sm font-medium rounded-md shadow-sm bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={handleSave}
          >
            Save data
          </button>
        </div>
      </div>
    </div>
  );
}

export default FatForm;
