import MacrosCalculator from "../../../components/nutrition/MacrosCalculator";
import { useParams } from "react-router-dom";
import { useFetchDietNeedByUserIdQuery } from "../../../store";
import TableCard from "../../../components/tables/TableCard";
import moment from "moment/moment";
import NutritionChart from "../../../components/nutrition/NutritionChart";
import { useState, useEffect } from "react";
import CreateMealPlanModal from "../../../components/modals/CreateMealPlanModal";
import DetailsMealPlanModal from "../../../components/modals/DetailsMealPlanModal";

function NutritionManager() {
  let { id } = useParams();
  const [openMealModal, setOpenMealModal] = useState(false);
  const [openDetailsMealModal, setOpenDetailsMealModal] = useState(false);

  const handleOpenMealModal = (event, dietNeed) =>{
    event.preventDefault();
    setModalData(dietNeed);
    setOpenMealModal(true);
  };
  const handleOpenDetailsMealModal = (event, dietNeed) =>{
    event.preventDefault();
    setDetailsMealModalData(dietNeed);
    setOpenDetailsMealModal(true);
  };

  const [modalData, setModalData] = useState({});
  const [detailsMealModalData, setDetailsMealModalData] = useState({});
  const config = [
    {
      key: 'Created',
      label: 'Created',
      render: (dietNeed) => moment(dietNeed.created_at).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'from',
      label: 'From',
      render: (dietNeed) => moment(dietNeed.from).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'to',
      label: 'To',
      render: (dietNeed) => moment(dietNeed.to).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Weight',
      label: 'Weight (kg)',
      render: (dietNeed) => dietNeed.weight,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'g/kg Carbohidrates',
      label: 'g/kg Carbohidrates',
      render: (dietNeed) => dietNeed.carbs,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'g/kg Protein',
      label: 'g/kg Protein',
      render: (dietNeed) => dietNeed.protein,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'g/kg Fat',
      label: 'g/kg Fat',
      render: (dietNeed) => dietNeed.fat,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'kcal',
      label: 'Kcal',
      render: (dietNeed) => dietNeed.kcal,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Neat',
      label: 'Neat',
      render: (dietNeed) => dietNeed.neat,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Water',
      label: 'Water',
      render: (dietNeed) => dietNeed.water,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Supplements',
      label: 'Supplements Protocol',
      render: (dietNeed) => dietNeed.supplements,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Objectives',
      label: 'Objectives',
      render: (dietNeed) => dietNeed.objectives,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Guidelines',
      label: 'Guidelines',
      render: (dietNeed) => dietNeed.guidelines,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'CreateMealPlan',
      label: '',
      render: (dietNeed) => (
        <button
          onClick={(event) => handleOpenMealModal(event, dietNeed)} 
          className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-white"
        >
          Create Meal Plan
        </button>
      ),
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'SeeMealPlan',
      label: '',
      render: (dietNeed) => (
      <>
        {dietNeed.diet && dietNeed.diet.length > 0 && (
        <button
          onClick={(event) => handleOpenDetailsMealModal(event, dietNeed)} 
          className="rounded-md bg-white border border-indigo-600 px-2.5 py-1.5 text-indigo-600"
        >
          See meal plans details
        </button>
        )}
      </>
      ),
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];


  const keyFn = (dietNeed) => {
    return dietNeed.id;
  };
  
  const { data, error, isLoading, refetch} = useFetchDietNeedByUserIdQuery(id); 

  useEffect(() => {
    if (!openMealModal) {
      refetch();
    }
  }, [openMealModal, refetch]);

  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <TableCard data={data} config={config} keyFn={keyFn} />;
  }
  return (
    <div>
      <div className="border-b border-gray-900/10 pb-12">
        <NutritionChart athleteId={id} />
      </div>
      <div className="border-b mt-2 border-gray-900/10 pb-12">
        <h3 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          Registry of calculated requirements
        </h3>
        
        {table}
      </div>
      <div className="border-t border-gray-900/10 pb-12">
        <MacrosCalculator/>
      </div>
      <CreateMealPlanModal open={openMealModal} dietNeed={modalData} setOpen={setOpenMealModal} />
      <DetailsMealPlanModal open={openDetailsMealModal} dietNeed={detailsMealModalData} setOpen={setOpenDetailsMealModal} />
    </div>
);
}

export default NutritionManager;