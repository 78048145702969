import SideBar from "../../components/navigation/SideBar";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchFoodQuery, useUpdateFoodMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import { useEffect, useState } from "react";

function EditFood(){
  let { id } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading} = useFetchFoodQuery(id);
  const [editFood, results] = useUpdateFoodMutation();
  const [selectedPreference1, setSelectedPreference1] = useState({
    "id": 1,
    "value": "Breakfast",
    "label": "Breakfast"
  })
  const onHandlePreference1Change = (e)=> {
    setSelectedPreference1(e);
  }
  const [selectedPreference2, setSelectedPreference2] = useState({
    "id": 4,
    "value": "Snack",
    "label": "Snack"
  })
  const onHandlePreference2Change = (e)=> {
    setSelectedPreference2(e);
  }
  const [selectedType, setSelectedType] = useState({
    "id": 1,
    "value": "Natural Raw",
    "label": "Natural Raw"
  })
  const onHandleTypeChange = (e)=> {
    setSelectedType(e);
  }

  useEffect(() => {
    if (data && data.length > 0) {
      let food = data[0];
      let valueOfPref1 = food.meal_preference1;
      let valueOfPref1Object = {};
      if (valueOfPref1 === "Breakfast") {
        valueOfPref1Object = {
          id: 1,
          value: "Breakfast",
          label: "Breakfast"
        };
      } else if(valueOfPref1 === "Lunch"){
        valueOfPref1Object = {
          id: 2,
          value: "Lunch",
          label: "Lunch"
        };
      }else if(valueOfPref1 === "Dinner"){
        valueOfPref1Object = {
          id: 3,
          value: "Dinner",
          label: "Dinner"
        };
      }else {
        valueOfPref1Object = {
          id: 4,
          value: "Snack",
          label: "Snack"
        };
      }
      setSelectedPreference1(valueOfPref1Object);

      let valueOfPref2 = food.meal_preference2;
      let valueOfPref2Object = {};
      if (valueOfPref2 === "Breakfast") {
        valueOfPref2Object = {
          id: 1,
          value: "Breakfast",
          label: "Breakfast"
        };
      } else if(valueOfPref2 === "Lunch"){
        valueOfPref2Object = {
          id: 2,
          value: "Lunch",
          label: "Lunch"
        };
      }else if(valueOfPref2 === "Dinner"){
        valueOfPref2Object = {
          id: 3,
          value: "Dinner",
          label: "Dinner"
        };
      }else {
        valueOfPref2Object = {
          id: 4,
          value: "Snack",
          label: "Snack"
        };
      }
      setSelectedPreference1(valueOfPref2Object);

      let valueOfType = food.type;
      let valueOfTypeObject = {};
      if (valueOfType === "Natural Raw") {
        valueOfTypeObject = {
          id: 1,
          value: "Natural Raw",
          label: "Natural Raw"
        };
      } else if(valueOfType === "Natural Cooked"){
        valueOfTypeObject = {
          id: 2,
          value: "Natural Cooked",
          label: "Natural Cooked"
        };
      }else if(valueOfType === "Product"){
        valueOfTypeObject = {
          id: 3,
          value: "Product",
          label: "Product"
        };
      }
      setSelectedType(valueOfTypeObject);
    }
  }, [data]);

  let config = [
    {
      id:'name_en',
      label: 'English Name',
      type: 'shortText',
      placeholder: 'Egss',
      size: 2
    },
    {
      id:'name_es',
      label: 'Spanish Name',
      type: 'shortText',
      placeholder: 'Huevos',
      size: 2
    },
    {
      id:'meal_preference1',
      label: 'Meal Preference 1',
      type: 'dropdown',
      placeholder: 'Choose preference',
      options: [
        { id: 1, value: 'Breakfast', label: 'Breakfast' },
        { id: 2, value: 'Lunch', label: 'Lunch' },
        { id: 3, value: 'Dinner', label: 'Dinner' },
        { id: 4, value: 'Snack', label: 'Snack' },
      ],
      size: 2,
      onChange: onHandlePreference1Change,
    },
    {
      id:'meal_preference2',
      label: 'Meal Preference 2',
      type: 'dropdown',
      placeholder: 'Choose preference',
      options: [
        { id: 1, value: 'Breakfast', label: 'Breakfast' },
        { id: 2, value: 'Lunch', label: 'Lunch' },
        { id: 3, value: 'Dinner', label: 'Dinner' },
        { id: 4, value: 'Snack', label: 'Snack' },
      ],
      size: 2,
      onChange: onHandlePreference2Change,
    },
    {
      id:'type',
      label: 'Type',
      type: 'dropdown',
      placeholder: 'Choose type',
      options: [
        { id: 1, value: 'Natural Raw', label: 'Natural Raw' },
        { id: 2, value: 'Natural Cooked', label: 'Natural Cooked' },
        { id: 3, value: 'Product', label: 'Product' },
      ],
      size: 2,
      onChange: onHandleTypeChange,
    },
    {
      id:'serving_size',
      label: 'Serving Size',
      type: 'unit',
      unit: 'g',
      size: 2
    },
    {
      id:'calories',
      label: 'Calories',
      type: 'unit',
      unit: 'kcal',
      size: 2
    },
    {
      id:'carbs',
      label: 'Carbs',
      type: 'unit',
      unit: 'g',
      size: 2
    },
    {
      id:'protein',
      label: 'Protein',
      type: 'unit',
      unit: 'g',
      size: 2
    },
    {
      id:'fat',
      label: 'Fat',
      type: 'unit',
      unit: 'g',
      size: 2
    },
    {
      id:'link_en',
      label: 'English Link',
      type: 'shortText',
      size: 2
    },
    {
      id:'link_es',
      label: 'Spanish Link',
      type: 'shortText',
      size: 2
    },
  ];

  const handleEdit = (e) => {
    e.preventDefault();
  
    e.target['meal_preference1'] = selectedPreference1;
    e.target['meal_preference2'] = selectedPreference2;
    e.target['type'] = selectedType;
  
    // Preparar el objeto 'submitData' con los datos del formulario
    const submitData = config.reduce((o, input) => {
      // Si el tipo del campo es checkbox, obtener su estado 'checked'
      if (input.type === 'check') {
        const checkbox = e.target.elements[input.id];
        return {
          ...o,
          [input.id]: checkbox ? checkbox.checked : false,
        };
      }
  
      // Para otros tipos de campos, usar el valor
      return {
        ...o,
        [input.id]: e.target[input.id]?.value,
      };
    }, {});
    
    editFood({id, submitData});
    navigate("/foods");
  };

  let meta;
  let basicDetailsForm;
  if (isLoading) {
    basicDetailsForm = <div> Loading</div>;
  } else if (error) {
    basicDetailsForm = <div> Errors</div>;
  } else {
    let food = {...data[0]};
    if(food.meal_preference1 ==="Breakfast"){
      food.meal_preference1 = {
        "id": 1,
        "value": "Breakfast",
        "label": "Breakfast"
      }
    }else if(food.meal_preference1 ==="Lunch"){
      food.meal_preference1 = {
        "id": 2,
        "value": "Lunch",
        "label": "Lunch"
      }
    }else if(food.meal_preference1 ==="Dinner"){
      food.meal_preference1 = {
        "id": 3,
        "value": "Dinner",
        "label": "Dinner"
      }
    }else{
      food.meal_preference1 = {
        "id": 4,
        "value": "Snack",
        "label": "Snack"
      }
    }

    if(food.meal_preference2 ==="Breakfast"){
      food.meal_preference2 = {
        "id": 1,
        "value": "Breakfast",
        "label": "Breakfast"
      }
    }else if(food.meal_preference2 ==="Lunch"){
      food.meal_preference2 = {
        "id": 2,
        "value": "Lunch",
        "label": "Lunch"
      }
    }else if(food.meal_preference2 ==="Dinner"){
      food.meal_preference2 = {
        "id": 3,
        "value": "Dinner",
        "label": "Dinner"
      }
    }else{
      food.meal_preference2 = {
        "id": 4,
        "value": "Snack",
        "label": "Snack"
      }
    }

    if(food.type ==="Natural Raw"){
      food.type = {
        "id": 1,
        "value": "Natural Raw",
        "label": "Natural Raw"
      }
    }else if(food.type ==="Natural Cooked"){
      food.type = {
        "id": 2,
        "value": "Natural Cooked",
        "label": "Natural Cooked"
      }
    }else if(food.type ==="Product"){
      food.type = {
        "id": 3,
        "value": "Product",
        "label": "Product"
      }
    }
    
    meta = {
      object: "Food",
      action: "Edit details of " + food.name_en + " / " + food.name_es,
      description: "Edit details of food.",
    };
    basicDetailsForm = <StandardForm meta={meta} config={config} data={food} onSubmit={handleEdit} />;
  }
  
  
  

  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {basicDetailsForm}
        </div>
      </main>
    </div>
    </>
  );
}

export default EditFood;