import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default function Meal({mealPlanDetails, athlete, index = 0}){
  var sortedMeals = [...mealPlanDetails.meals].sort((a, b) => {
    if ((a.type === "Preworkout" || a.type === "Postworkout") && (b.type !== "Preworkout" && b.type !== "Postworkout")) {
      return 1;
    } else if ((a.type !== "Preworkout" && a.type !== "Postworkout") && (b.type === "Preworkout" || b.type === "Postworkout")) {
      return -1;
    } else {
      return 0;
    }
  });
  const handleDownload = async (e) => {
    e.preventDefault();
    const doc = new jsPDF('p', 'pt', 'a4');
    const margin = 20;
    const pageHeight = doc.internal.pageSize.getHeight();
    let currentHeight = margin + 120; // Altura inicial después del logo
  
    // Logo (deberías ajustar la ruta del logo aquí)
    const logoSrc = '/images/logoHeadBlack.png';
    const logo = new Image();
    logo.src = logoSrc;
  
    await new Promise((resolve) => {
      logo.onload = () => {
        const imgWidth = 100;
        const imgHeight = 100;
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.addImage(
          logo,
          'PNG',
          (pageWidth - imgWidth) / 2,
          margin,
          imgWidth,
          imgHeight
        );
        resolve();
      };
    });
    const pageWidth = doc.internal.pageSize.getWidth();
    var programName = "Meal Plan "+(index+1)+ " for "+(athlete.firstname);
    const programTextWidth = doc.getTextWidth(programName);
    doc.text(programName, (pageWidth - programTextWidth) / 2, 125);
    const input = document.getElementById(`table-${index+1}`);
    const canvas = await html2canvas(input, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const imgWidth = doc.internal.pageSize.getWidth() - margin * 2;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // Verifica si la imagen cabe en la página actual
    if (currentHeight + imgHeight > pageHeight - margin) {
      doc.addPage();
      currentHeight = margin + 20; // Resetear altura para la nueva página
    }

    doc.addImage(imgData, 'PNG', margin, currentHeight, imgWidth, imgHeight);
    currentHeight += imgHeight + 20; // Agregar un pequeño margen entre tablas
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    var copyrightText ="Incredibilis 4 Fitness - www.incredibilis.fitness";
    const copyrightTextWidth = doc.getTextWidth(copyrightText);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(copyrightText, (pageWidth - copyrightTextWidth) / 2, doc.internal.pageSize.getHeight() - margin);
      doc.text(`${i} / ${pageCount}`, doc.internal.pageSize.getWidth() - margin - 20,doc.internal.pageSize.getHeight() - margin);
    }
    doc.save(programName+'.pdf');
  };

  return(
    <>
    <h3 className="text-lg font-medium text-gray-700">Suggested Diet Plan {index+1}:</h3>
    <button
      type="button"
      onClick={handleDownload}
      className="inline-flex justify-center ml-2 rounded-md bg-indigo-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      Download diet
    </button>
    
    <div id={`table-${index+1}`} className="bg-white mt-5 mb-8 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-6 sm:p-8">
        {/* <div className="grid grid-rows-1 grid-cols-5 grid-flow-col gap-2"> */}
        <div className="">
          {/* <div className="row-span-3">
          </div> */}
          {/* <div className="col-span-4"> */}
          <div className="">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                {/* <thead>
                  <tr>
                    <th className="py-2 px-4 border-b border-gray-200 text-left w-1/4">Exercise</th>
                    <th className="py-2 px-4 border-b border-gray-200 text-left w-1/4">Objective</th>
                    <th className="py-2 px-4 border-b border-gray-200 text-left w-1/2">Execution</th>
                  </tr>
                </thead> */}
                <tbody>
                  <tr>
                    <td colSpan="2" className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top"></td>
                    <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{mealPlanDetails.calories.toFixed(2)} kcal</td>
                    <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{mealPlanDetails.carbs.toFixed(2)}g of carbs</td>
                    <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{mealPlanDetails.protein.toFixed(2)}g of protein</td>
                    <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{mealPlanDetails.fat.toFixed(2)}g of fat</td>
                  </tr>
                  {sortedMeals.map((meal) => (
                    <React.Fragment key={meal.id}>
                      <tr>
                        <td colSpan="2" className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{meal.name}</td>
                        <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{meal.calories.toFixed(2)} kcal</td>
                        <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{meal.carbs.toFixed(2)}g of carbs</td>
                        <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{meal.protein.toFixed(2)}g of protein</td>
                        <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{meal.fat.toFixed(2)}g of fat</td>
                      </tr>
                      {meal.foods.map((food) => (
                        <tr key={food.name}>
                          <td className="py-2 px-4 border-b border-gray-200">
                            -
                          </td>
                          <td className="py-2 px-4 border-b text-left border-gray-200">
                            {food.grams}g {food.name} 
                          </td>
                          <td className="py-2 px-4 border-b text-left border-gray-200">
                            {food.calories} kcal 
                          </td>
                          <td className="py-2 px-4 border-b text-left border-gray-200">
                            {food.carbs}g of carbs
                          </td>
                          <td className="py-2 px-4 border-b text-left border-gray-200">
                            {food.protein}g of protein
                          </td>
                          <td className="py-2 px-4 border-b text-left border-gray-200">
                             {food.fat}g of fat
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}