
import { useFetchPlicometryByAtheleteIdQuery } from "../../store";
import TableCard from "../tables/TableCard";
import moment from "moment/moment";
import { calculatePlicometry } from "../../services/plicometry.service";

function PlicoTable({athlete}) {
  const config = [
    {
      key: 'Date',
      label: 'Date',
      render: (plico) => moment(plico.date).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Weight',
      label: 'Weight',
      render: (plico) => plico.weight +' kg',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Height',
      label: 'Height',
      render: (plico) => plico.height +' cm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'LBM',
      label: 'LBM',
      render: (plico) => {
        if(plico.method != null){
          const calculated = calculatePlicometry(plico,athlete, plico.method);
          return (
              calculated.lbm +' kg'
          );
        }else{
          return (
            plico.lbm+' kg'
          );
        }
      },
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Fat',
      label: 'Fat',
      render: (plico) => {
        if(plico.method != null){
          const calculated = calculatePlicometry(plico,athlete, plico.method);
          return (
              calculated.bodyFat+' %'
          );
        }else{
          return (
              plico.fat_percentage+' %'
          );
        }
      },
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Method',
      label: 'Calculation Method',
      render: (plico) => (plico?.method!= null ? plico.method : ''),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Chest',
      label: 'Chest',
      render: (plico) => (plico?.chest != null ? `${plico.chest} mm` : ''),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Midaxilla',
      label: 'Midaxilla',
      render: (plico) => (plico?.midaxilla != null ? `${plico.midaxilla} mm` : ''),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Abdomen',
      label: 'Abdomen',
      render: (plico) => (plico?.abdomen != null ? `${plico.abdomen} mm` : ''),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Suprailiac',
      label: 'Suprailiac',
      render: (plico) => (plico?.suprailiac != null ? `${plico.suprailiac} mm` : ''),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Quadriceps',
      label: 'Quadriceps/Thigh',
      render: (plico) => (plico?.quadriceps != null ? `${plico.quadriceps} mm` : ''),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Subscapular',
      label: 'Subscapular',
      render: (plico) => (plico?.subscapular != null ? `${plico.subscapular} mm` : ''),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Triceps',
      label: 'Triceps',
      render: (plico) => (plico?.triceps != null ? `${plico.triceps} mm` : ''),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    
    {
      key: 'Other',
      label: 'Other',
      render: (plico) => {
        if(plico?.other != null){
          <div>
          {plico.other.map((item, index) => (
            <p key={index}>{item.name} - {item.value +' mm'}</p>
          ))}
          </div>
        }
        else{
          <div></div>
        }
      },
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];


  const keyFn = (plico) => {
    return plico.id;
  };

  const { data, error, isLoading} = useFetchPlicometryByAtheleteIdQuery(athlete.id); 
  let table;
  let notes;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <TableCard data={data} config={config} keyFn={keyFn} />;
    notes = data.some((plico) => plico.notes) ? ( // Verifica si hay algún plico con notes
      <div className="mt-6 space-y-4">
        <h3 className="text-lg font-semibold text-gray-900">History of notes:</h3>
        {data
          .filter((plico) => plico.notes) // Filtra solo los elementos con notes
          .map((plico) => (
            <div key={plico.id} className="p-4 border rounded-md shadow-sm bg-white">
              <p>
                <span className="font-medium text-gray-800">Date:</span>{' '}
                {moment(plico.date).format('DD/MM/YYYY')}
              </p>
              <p>
                <span className="font-medium text-gray-800">Notes:</span>{' '}
                {plico.notes}
              </p>
            </div>
          ))}
      </div>
    ) : null; // Si no hay notes, no muestra nada
  }
  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        {table}
      </div>
      {notes}
    </div>
  );
}

export default PlicoTable;