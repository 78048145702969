import React from 'react';
import { changeNumberMealsInPlan, changeNumberSnacksInPlan, changePreworkoutInPlan, changePostworkoutInPlan, removeFoodFromMeal, editGramsFoodFromMeal, changeMealPlanLanguage
} from "../../store";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from "react";
import NumberMealsSlider from '../nutrition/NumberMealsSlider';
import ToggleWithLabel from '../forms/inputs/ToggleWithLabel';
import ChooseFoodModal from '../modals/ChooseFoodModal';
import { MinusIcon } from '@heroicons/react/24/solid';
import DropDown from '../forms/inputs/DropDown';
  

export default function MealPlanMaker({dietNeed}){
  const { meals, calories, carbs, protein, fat } = useSelector((state) => {
    return {
      meals: state.mealPlanMaker.meals,
      calories: state.mealPlanMaker.calories,
      carbs: state.mealPlanMaker.carbs,
      protein: state.mealPlanMaker.protein,
      fat: state.mealPlanMaker.fat,
    };
  });
  const dispatch = useDispatch();

  const nutrition_requirements = [
    {name: "Carbohidrates"},
    {name: "Proteins"},
    {name: "Lipids"},
    {name: "KCal"},
  ];

  const [openModal, setOpenModal] = useState(false);
  const [modalMealId, setModalMealId] = useState(false);
  const onClickAddFood = (event, mealId) =>{
    event.preventDefault();
    setModalMealId(mealId);
    setOpenModal(true);
  };
  const onClickRemoveFood = (event, foodKey) =>{
    event.preventDefault();
    dispatch(removeFoodFromMeal({foodKey: foodKey}));
  };
  
  const handleFoodGramsSlider = (grams,foodKey) =>{
    dispatch(editGramsFoodFromMeal({foodKey: foodKey, grams: grams}));
  };

  const handleNumberMealsChange = (rangeValue) =>{
    var rangeInt = parseInt(rangeValue);
    dispatch(changeNumberMealsInPlan(rangeInt));
  };
  const handleNumberSnacksChange = (rangeValue) =>{
    var rangeInt = parseInt(rangeValue);
    dispatch(changeNumberSnacksInPlan(rangeInt));
  };
  const handlePreWorkoutChange = (value) =>{
    dispatch(changePreworkoutInPlan(value));
  };
  const handlePostWorkoutChange = (value) =>{
    dispatch(changePostworkoutInPlan(value));
  };
  const handleMealLanguageChange = (item) =>{
    dispatch(changeMealPlanLanguage(item));
  };

  return (
    <div className="grid grid-rows-1 grid-cols-5 grid-flow-col gap-2">
      <div className="row-span-3">
        {dietNeed && (
        <div className="row-span-3">
          <table className="min-w-fit divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Macros
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Daily Average
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  g/kg body weight
                </th>
                
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
            {nutrition_requirements.map((req) => (
              <tr key={req.name}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0">
                  {req.name}
                </td> 
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {req.name === "KCal" ? dietNeed.kcal: ""}
                  {req.name === "Carbohidrates"? (dietNeed.carbs*dietNeed.weight).toFixed(2): ""}
                  {req.name === "Proteins" ? dietNeed.protein*dietNeed.weight: ""}
                  {req.name === "Lipids" ? dietNeed.fat*dietNeed.weight: ""}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {req.name === "Carbohidrates" ? dietNeed.carbs: ""}
                  {req.name === "Proteins" ? dietNeed.protein: ""}
                  {req.name === "Lipids" ? dietNeed.fat: ""}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        )}
      </div>
      <div className="col-span-4">
        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid grid-cols-6 gap-x-6 gap-y-8">
              <div className="col-span-1">
                <label htmlFor="objective" className="block text-sm text-left font-medium leading-6 text-gray-900">
                  Number of main meals
                </label>
                <div className="">
                  <NumberMealsSlider elementID="numberMeals"
                    min={1}
                    max={7}
                    unit="main meals"
                    initialValue={3}
                    onChange={handleNumberMealsChange}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label htmlFor="objective" className="block text-sm text-left font-medium leading-6 text-gray-900">
                  Number of snack breaks
                </label>
                <div className="">
                  <NumberMealsSlider elementID="numberSnacks"
                    min={0}
                    max={7}
                    unit="snack breaks"
                    initialValue={0}
                    onChange={handleNumberSnacksChange}
                  />
                </div>
              </div>
              <div className="">
                <div className="">
                  <ToggleWithLabel onChange={handlePreWorkoutChange} mainLabel="Preworkout"/>
                </div>
              </div>
              <div className="">
                <div className="">
                  <ToggleWithLabel onChange={handlePostWorkoutChange} mainLabel="Postworkout"/>
                </div>
              </div>
              <div className="">
                <label htmlFor="objective" className="block text-sm text-left font-medium leading-6 text-gray-900">
                  Meal Plan Language
                </label>
                <div className="">
                  <DropDown id="language"
                    options={[
                      { id: 1, value:'English', label: 'English'},
                      { id: 2, value:'Spanish', label: 'Spanish'},
                    ]}
                    onChange={handleMealLanguageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="col-span-4">
        <div id={`table-${"Meal"+1}`} className="bg-white mt-5 mb-8 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            {/* <div className="grid grid-rows-1 grid-cols-5 grid-flow-col gap-2"> */}
            <div className="">
              {/* <div className="row-span-3">
              </div> */}
              {/* <div className="col-span-4"> */}
              <div className="">
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    {/* <thead>
                      <tr>
                        <th className="py-2 px-4 border-b border-gray-200 text-left w-1/4">Exercise</th>
                        <th className="py-2 px-4 border-b border-gray-200 text-left w-1/4">Objective</th>
                        <th className="py-2 px-4 border-b border-gray-200 text-left w-1/2">Execution</th>
                      </tr>
                    </thead> */}
                    <tbody>
                      <tr>
                        <td colSpan="2" className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">Meal Plan Macros</td>
                        <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{calories.toFixed(2)} kcal</td>
                        <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{carbs.toFixed(2)}g of carbs</td>
                        <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{protein.toFixed(2)}g of protein</td>
                        <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{fat.toFixed(2)}g of fat</td>
                      </tr>
                      {meals.map((meal) => (
                        <React.Fragment key={`meal-${meal.id}`}>
                          <tr>
                            <td colSpan="2" className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">
                              {meal.name}
                              <button
                                type="button"
                                onClick={(event) => {onClickAddFood(event,meal.id)}}
                                className="rounded bg-white px-2 py-1 text-sm ml-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                Add food
                            </button>
                            </td>
                            <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{meal.calories.toFixed(2)} kcal</td>
                            <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{meal.carbs.toFixed(2)}g of carbs</td>
                            <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{meal.protein.toFixed(2)}g of protein</td>
                            <td className="py-2 px-4 text-left border-b border-gray-200 font-bold align-top">{meal.fat.toFixed(2)}g of fat</td>
                          </tr>
                          {meal.foods.map((food) => (
                            <tr key={food.name}>
                              <td className="py-2 px-4 border-b border-gray-200">
                              <button
                                  type="button"
                                  onClick={(event) => {onClickRemoveFood(event,food.key)}}
                                  className="rounded-full mx-1 bg-red-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                              </td>
                              <td className="py-2 px-4 border-b text-left border-gray-200">
                                {/* {food.grams}g {food.name}  */}

                                <div className="">
                                  <NumberMealsSlider elementID={`gramsof-${food.key}`}
                                    min={0}
                                    max={500}
                                    unit={`g ${food.name}`}
                                    initialValue={food.grams}
                                    onChange={(grams) => {handleFoodGramsSlider(grams,food.key)}}
                                  />
                                </div>
                              </td>
                              <td className="py-2 px-4 border-b text-left border-gray-200">
                                {food.calories} kcal 
                              </td>
                              <td className="py-2 px-4 border-b text-left border-gray-200">
                                {food.carbs}g of carbs
                              </td>
                              <td className="py-2 px-4 border-b text-left border-gray-200">
                                {food.protein}g of protein
                              </td>
                              <td className="py-2 px-4 border-b text-left border-gray-200">
                                  {food.fat}g of fat
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChooseFoodModal open={openModal} setOpen={setOpenModal} mealId={modalMealId}/>
    </div>
  )
}