import { createSlice, nanoid } from "@reduxjs/toolkit";

const mealPlanMakerSlice = createSlice({
  name: "mealPlanMaker",
  initialState: {
    numberMeals: 3,
    numberSnacks: 0,
    preworkout: false,
    postworkout: false,
    mealLanguage: { id: 1, value:'English', label: 'English'},
    calories: 0,
    carbs: 0,
    protein: 0,
    fat: 0,
    meals:[
      {
        "id": 1,
        "name": "Breakfast",
        "type": "Main meal",
        "foods": [],
        "calories": 0,
        "carbs": 0,
        "protein": 0,
        "fat": 0,
      },
      {
        "id": 2,
        "name": "Lunch",
        "type": "Main meal",
        "foods": [],
        "calories": 0,
        "carbs": 0,
        "protein": 0,
        "fat": 0,
      },
      {
        "id": 3,
        "name": "Dinner",
        "type": "Main meal",
        "foods": [],
        "calories": 0,
        "carbs": 0,
        "protein": 0,
        "fat": 0,
      }
    ,]
  },
  reducers: {
    changeNumberMealsInPlan(state, action) {
      if (action.payload < state.numberMeals) {
        // Filtrar solo las comidas principales y quitar ultimo
        const mainMeals = state.meals.filter(meal => meal.type === "Main meal").slice(0, -1);
        mainMeals.forEach((meal, index) => {
          if(index === 0){
            meal.name = "Breakfast";
          }else if(index+1 === mainMeals.length){
            meal.name = "Dinner";
          }else{
            meal.name = `Main meal ${index + 1}`;
          }
        });
        if(action.payload === 3){
          mainMeals.forEach((meal, index) => {
            if(index === 0){
              meal.name = "Breakfast";
            }else if(index+1 === mainMeals.length){
              meal.name = "Dinner";
            }else{
              meal.name = "Lunch";
            }
          });
        }
        // Mantener los snacks intactos
        const snacks = state.meals.filter(meal => meal.type === "Snack meal");
        const preWorkout = state.meals.filter(meal => meal.type === "Preworkout");
        const postWorkout = state.meals.filter(meal => meal.type === "Postworkout");
        // Actualizar el estado con los nuevos valores
        state.meals = [...mainMeals, ...snacks, ...preWorkout, ...postWorkout];
      }
      if (action.payload > state.numberMeals) {
        // Filtrar solo las comidas principales
        const mainMeals = [...state.meals.filter(meal => meal.type === "Main meal"),
          {
            "id": nanoid(),
            "name": "Dinner",
            "type": "Main meal",
            "foods": [],
            "calories": 0,
            "carbs": 0,
            "protein": 0,
            "fat": 0,
          }
        ];
        mainMeals.forEach((meal, index) => {
          if(index === 0){
            meal.name = "Breakfast";
          }else if(index+1 === mainMeals.length){
            meal.name = "Dinner";
          }else{
            meal.name = `Main meal ${index + 1}`;
          }
        });
        if(action.payload === 3){
          mainMeals.forEach((meal, index) => {
            if(index === 0){
              meal.name = "Breakfast";
            }else if(index+1 === mainMeals.length){
              meal.name = "Dinner";
            }else{
              meal.name = "Lunch";
            }
          });
        }

        // Mantener los snacks intactos
        const snacks = state.meals.filter(meal => meal.type === "Snack meal");
        const preWorkout = state.meals.filter(meal => meal.type === "Preworkout");
        const postWorkout = state.meals.filter(meal => meal.type === "Postworkout");
        // Actualizar el estado con los nuevos valores
        state.meals = [...mainMeals, ...snacks, ...preWorkout, ...postWorkout];
      }
      state.numberMeals = action.payload;
    },
    changeNumberSnacksInPlan(state, action) {
      if(action.payload < state.numberSnacks){
        const mainMeals = state.meals.filter(meal => meal.type === "Main meal")
        const snacks = state.meals.filter(meal => meal.type === "Snack meal").slice(0, -1);
        const preWorkout = state.meals.filter(meal => meal.type === "Preworkout");
        const postWorkout = state.meals.filter(meal => meal.type === "Postworkout");
        state.meals = [...mainMeals, ...snacks, ...preWorkout, ...postWorkout];
      }
      if(action.payload > state.numberSnacks){
        var numberofSnacks = state.numberSnacks+1;
        
        const mainMeals = state.meals.filter(meal => meal.type === "Main meal")
        const snacks = state.meals.filter(meal => meal.type === "Snack meal");
        const preWorkout = state.meals.filter(meal => meal.type === "Preworkout");
        const postWorkout = state.meals.filter(meal => meal.type === "Postworkout");
        // Actualizar el estado con los nuevos valores
        state.meals = [...mainMeals, ...snacks, ...preWorkout, ...postWorkout];
        var newMeal = {
          "id": nanoid(),
          "name": "Snack "+numberofSnacks,
          "type": "Snack meal",
          "foods": [],
          "calories": 0,
          "carbs": 0,
          "protein": 0,
          "fat": 0,
        }
        state.meals = [...mainMeals, ...snacks, newMeal, ...preWorkout, ...postWorkout];
      }
      state.numberSnacks= action.payload;
    },
    changePreworkoutInPlan(state, action) {
      state.preworkout = action.payload;
      if(action.payload === true){
        var newMeal = {
          "id": nanoid(),
          "name": "Preworkout ",
          "type": "Preworkout",
          "foods": [],
          "calories": 0,
          "carbs": 0,
          "protein": 0,
          "fat": 0,
        }
        const mainMeals = state.meals.filter(meal => meal.type === "Main meal");
        const snackMeals = state.meals.filter(meal => meal.type === "Snack meal");
        const postWorkout = state.meals.filter(meal => meal.type === "Postworkout");
        state.meals = [...mainMeals, ...snackMeals, newMeal, ...postWorkout];
      }else{
        //Coger los distintos tipos de meals
        const mainMeals = state.meals.filter(meal => meal.type === "Main meal");
        const snackMeals = state.meals.filter(meal => meal.type === "Snack meal");
        const postWorkout = state.meals.filter(meal => meal.type === "Postworkout");
        state.meals = [...mainMeals, ...snackMeals, ...postWorkout];
      }
    },
    changePostworkoutInPlan(state, action) {
      state.postworkout = action.payload;
      state.preworkout = action.payload;
      if(action.payload === true){
        var newMeal = {
          "id": nanoid(),
          "name": "Postworkout ",
          "type": "Postworkout",
          "foods": [],
          "calories": 0,
          "carbs": 0,
          "protein": 0,
          "fat": 0,
        }
        const mainMeals = state.meals.filter(meal => meal.type === "Main meal");
        const snackMeals = state.meals.filter(meal => meal.type === "Snack meal");
        const preWorkout = state.meals.filter(meal => meal.type === "Preworkout");
        state.meals = [...mainMeals, ...snackMeals, ...preWorkout, newMeal];
      }else{
        //Coger los distintos tipos de meals
        const mainMeals = state.meals.filter(meal => meal.type === "Main meal");
        const snackMeals = state.meals.filter(meal => meal.type === "Snack meal");
        const preWorkout = state.meals.filter(meal => meal.type === "Preworkout");
        state.meals = [...mainMeals, ...snackMeals, ...preWorkout];
      }
    },
    addFoodToMeal(state, action) {
      const { mealId, food } = action.payload;
      const language = state.mealLanguage.value;
      state.meals = state.meals.map(meal => {
        if (meal.id === mealId) {
          // Agregar nuevo alimento a la lista de foods
          const updatedFoods = [
            ...meal.foods,
            {
              key: nanoid(),
              id: food.id,
              name: language==="English" ? food.name_en: food.name_es,
              grams: food.serving_size,
              calories: food.calories,
              carbs: food.carbs,
              protein: food.protein,
              fat: food.fat,
              serving_size: food.serving_size,
              calories_sz: food.calories,
              carbs_sz: food.carbs,
              protein_sz: food.protein,
              fat_sz: food.fat,
              name_alt: language==="English" ? food.name_es: food.name_en,
            }
          ];
    
          // Recalcular los valores nutricionales sumando todos los alimentos
          const totalCalories = updatedFoods.reduce((acc, f) => acc + f.calories, 0);
          const totalCarbs = updatedFoods.reduce((acc, f) => acc + f.carbs, 0);
          const totalProtein = updatedFoods.reduce((acc, f) => acc + f.protein, 0);
          const totalFat = updatedFoods.reduce((acc, f) => acc + f.fat, 0);
    
          return {
            ...meal,
            foods: updatedFoods,
            calories: totalCalories,
            carbs: totalCarbs,
            protein: totalProtein,
            fat: totalFat
          };
        }
        return meal;
      });
      // Recalcular los valores nutricionales sumando todos los macros de los meals
      state.calories = state.meals.reduce((acc, m) => acc + m.calories, 0);
      state.carbs = state.meals.reduce((acc, m) => acc + m.carbs, 0);
      state.protein = state.meals.reduce((acc, m) => acc + m.protein, 0);
      state.fat = state.meals.reduce((acc, m) => acc + m.fat, 0);
    },
    removeFoodFromMeal(state, action) {
      const { foodKey } = action.payload;
      state.meals = state.meals.map(meal => {
        // Filtrar los alimentos, eliminando aquel que coincida con foodKey
        const updatedFoods = meal.foods.filter(food => food.key !== foodKey);
        
        // Recalcular los valores nutricionales totales
        const totalCalories = updatedFoods.reduce((acc, f) => acc + f.calories, 0);
        const totalCarbs = updatedFoods.reduce((acc, f) => acc + f.carbs, 0);
        const totalProtein = updatedFoods.reduce((acc, f) => acc + f.protein, 0);
        const totalFat = updatedFoods.reduce((acc, f) => acc + f.fat, 0);
    
        return {
          ...meal,
          foods: updatedFoods,
          calories: totalCalories,
          carbs: totalCarbs,
          protein: totalProtein,
          fat: totalFat
        };
      });
      // Recalcular los valores nutricionales sumando todos los macros de los meals
      state.calories = state.meals.reduce((acc, m) => acc + m.calories, 0);
      state.carbs = state.meals.reduce((acc, m) => acc + m.carbs, 0);
      state.protein = state.meals.reduce((acc, m) => acc + m.protein, 0);
      state.fat = state.meals.reduce((acc, m) => acc + m.fat, 0);
    },
    editGramsFoodFromMeal(state, action) {
      const { foodKey, grams } = action.payload;
      
      state.meals = state.meals.map(meal => {
        const updatedFoods = meal.foods.map(food => {
          if (food.key === foodKey) {
            // Calcular los nuevos valores nutricionales
            const newCalories = (grams / food.serving_size) * food.calories_sz;
            const newCarbs = (grams / food.serving_size) * food.carbs_sz;
            const newProtein = (grams / food.serving_size) * food.protein_sz;
            const newFat = (grams / food.serving_size) * food.fat_sz;
        
            // Redondear a dos decimales manteniendo el valor como número
            return {
              ...food,
              grams: grams,
              calories: Math.round(newCalories * 100) / 100,
              carbs: Math.round(newCarbs * 100) / 100,
              protein: Math.round(newProtein * 100) / 100,
              fat: Math.round(newFat * 100) / 100,
            };
          }
          return food;
        });
        
        // Recalcular los valores nutricionales totales
        const totalCalories = updatedFoods.reduce((acc, f) => acc + f.calories, 0);
        const totalCarbs = updatedFoods.reduce((acc, f) => acc + f.carbs, 0);
        const totalProtein = updatedFoods.reduce((acc, f) => acc + f.protein, 0);
        const totalFat = updatedFoods.reduce((acc, f) => acc + f.fat, 0);
    
        return {
          ...meal,
          foods: updatedFoods,
          calories: totalCalories,
          carbs: totalCarbs,
          protein: totalProtein,
          fat: totalFat
        };
      });
      // Recalcular los valores nutricionales sumando todos los macros de los meals
      state.calories = state.meals.reduce((acc, m) => acc + m.calories, 0);
      state.carbs = state.meals.reduce((acc, m) => acc + m.carbs, 0);
      state.protein = state.meals.reduce((acc, m) => acc + m.protein, 0);
      state.fat = state.meals.reduce((acc, m) => acc + m.fat, 0);
    },
    changeMealPlanLanguage(state, action) {
      state.mealLanguage = action.payload;
      state.meals = state.meals.map(meal => {
        const updatedFoods = meal.foods.map(food => {
          const prevName = food.name;
          const altName = food.name_alt;
          return {
            ...food,
            name: altName,
            name_alt: prevName,
          };
        });
        return {
          ...meal,
          foods: updatedFoods,
        };
      });
    },
  }
});

export const { changeNumberMealsInPlan, changeNumberSnacksInPlan, changePreworkoutInPlan, changePostworkoutInPlan, addFoodToMeal, removeFoodFromMeal, editGramsFoodFromMeal,
  changeMealPlanLanguage
} = mealPlanMakerSlice.actions;
export const mealPlanMakerReducer = mealPlanMakerSlice.reducer;
